import { ElementType } from 'react';
import FrameLayer from '../layers/FrameLayer';
import GroupLayer from '../layers/GroupLayer';
import ImageLayer from '../layers/ImageLayer';
import LineLayer from '../layers/LineLayer';
import QrCodeLayer from '../layers/QrCodeLayer';
import RootLayer from '../layers/RootLayer';
import ShapeLayer from '../layers/ShapeLayer';
import SvgLayer from '../layers/SvgLayer';
import TextLayer from '../layers/TextLayer';
import VideoLayer from '../layers/VideoLayer';

export const resolvers: Record<string, ElementType> = {
  RootLayer,
  TextLayer,
  ImageLayer,
  ShapeLayer,
  FrameLayer,
  SvgLayer,
  GroupLayer,
  LineLayer,
  QrCodeLayer,
  VideoLayer,
};

import { rgb2hsv } from '../rgb2hsv';
import { HSVAColor } from '../types';
import { hexColorRegex, parseHex } from './parse-hex';
import { parseRgba, rgbColorRegex } from './parse-rgba';

export const parseColor = (color: string): HSVAColor => {
  let rgbColor;
  if (rgbColorRegex.test(color)) {
    rgbColor = parseRgba(color);
  } else if (hexColorRegex.test(color)) {
    rgbColor = parseHex(color);
  }

  if (rgbColor) {
    return rgb2hsv(rgbColor);
  }
  throw new Error(`Cannot parse ${color}`);
};

import { LineContent, LineContentProps } from '@lidojs/design-layers';
import React, { FC } from 'react';

export type LineLayerProps = LineContentProps;
const ShapeLayer: FC<LineLayerProps> = ({
  layerId,
  boxSize,
  color,
  scale = 1,
  rotate,
  position,
  ...props
}) => {
  return (
    <div
      css={{
        transformOrigin: '0 0',
      }}
      style={{
        width: boxSize.width / (scale || 1),
        height: boxSize.height / (scale || 1),
        transform: `scale(${scale || 1})`,
      }}
    >
      <LineContent
        boxSize={boxSize}
        color={color}
        layerId={layerId}
        position={position}
        rotate={rotate}
        scale={scale}
        {...props}
      />
    </div>
  );
};

export default ShapeLayer;

import { QrCodeContent, QrCodeContentProps } from '@lidojs/design-layers';
import React, { FC } from 'react';

export type QrCodeLayerProps = QrCodeContentProps;

const QrCodeLayer: FC<QrCodeLayerProps> = ({ boxSize, scale, ...props }) => {
  return (
    <div
      style={{
        width: boxSize.width / scale,
        height: boxSize.height / scale,
        transform: `scale(${scale})`,
      }}
    >
      <QrCodeContent boxSize={boxSize} scale={scale} {...props} />
    </div>
  );
};

export default QrCodeLayer;

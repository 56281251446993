import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import * as ReactDOM from 'react-dom/client';
import { v4 } from 'uuid';
import App from './App';

if (process.env.MODE === 'production') {
  // Sentry.init({
  //   dsn: process.env.SENTRY_DSN,
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //       tracePropagationTargets: [process.env.SENTRY_TARGET||'localhost'],
  //     }),
  //     new Sentry.Replay(),
  //   ],
  // });
  let uid = window.localStorage.getItem('uid');
  if (!uid) {
    uid = v4();
    window.localStorage.setItem('uid', uid);
  }
  // Sentry.setUser({ id: uid });
  FullStory.identify(uid);
}
// axios.defaults.baseURL = 'https://editor-api.lidojs.com';
axios.defaults.baseURL = process.env.SERVER_URL;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

import XIcon from '@duyank/icons/regular/X';
import React, {
  ChangeEvent,
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useEditor, useSelectedLayers } from '../../hooks';
import { QrCodeLayerProps } from '../../layers/QrCodeLayer';
import { Layer } from '../../types';
import { isQrCodeLayer } from '../../ultils/layer/layers';
import Sidebar, { SidebarProps } from './Sidebar';

type QrCodeConfigurationSidebarProps = SidebarProps;
const QrCodeConfigurationSidebar: ForwardRefRenderFunction<
  HTMLDivElement,
  QrCodeConfigurationSidebarProps
> = ({ ...props }, ref) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState<string>('');
  const [image, setImage] = useState<string | null>(null);
  const { layerList } = useSelectedLayers();
  const { actions, activePage } = useEditor((state) => ({
    activePage: state.activePage,
  }));

  const layer = layerList.find((l): l is Layer<QrCodeLayerProps> =>
    isQrCodeLayer(l)
  );
  useEffect(() => {
    setUrl(layer.data.props.text);
    setImage(layer.data.props.logo);
  }, [layer.data.props.logo, layer.data.props.text]);

  const updateQrCodeData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    actions.setProp<QrCodeLayerProps>(activePage, layer.id, {
      text: url,
      logo: image,
    });
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Sidebar ref={ref} {...props}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          QrCode Configuration
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            actions.setSidebar();
          }}
        >
          <XIcon />
        </div>
      </div>
      <form css={{ marginTop: 20 }} onSubmit={updateQrCodeData}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
            rowGap: 12,
          }}
        >
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div css={{ flexShrink: 0, width: 60 }}>URL</div>
            <div
              css={{
                borderRadius: 4,
                boxShadow: '0 0 0 1px rgba(43,59,74,.3)',
                overflow: 'hidden',
                flexGrow: 1,
              }}
            >
              <div
                css={{
                  height: 40,
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  padding: 12,
                }}
              >
                <input
                  css={{ width: '100%', height: '100%' }}
                  defaultValue={url}
                  type={'text'}
                  onChange={(e) => setUrl(e.target.value as string)}
                />
              </div>
            </div>
          </div>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div css={{ flexShrink: 0, width: 60 }}>Logo</div>
            <div
              css={{
                background: '#3a3a4c',
                borderRadius: 8,
                color: '#fff',
                padding: '8px 16px',
                cursor: 'pointer',
                textAlign: 'center',
              }}
              onClick={() => inputFileRef.current?.click()}
            >
              Choose
            </div>
          </div>
          {image && (
            <div
              css={{
                position: 'relative',
                width: '100%',
                paddingBottom: '100%',
              }}
            >
              <div css={{ position: 'absolute', inset: 0 }}>
                <img
                  css={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                  src={image}
                />
              </div>
              <div
                css={{
                  position: 'absolute',
                  right: -10,
                  top: -10,
                  fontSize: 20,
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                  background: '',
                }}
              >
                <XIcon />
              </div>
            </div>
          )}
          <input
            ref={inputFileRef}
            accept="image/*"
            css={{ display: 'none' }}
            type={'file'}
            onChange={handleUpload}
          />
          <button
            css={{
              background: '#3a3a4c',
              borderRadius: 8,
              color: '#fff',
              padding: '12px 16px',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            type="submit"
          >
            Generate
          </button>
        </div>
      </form>
    </Sidebar>
  );
};

export default forwardRef<HTMLDivElement, QrCodeConfigurationSidebarProps>(
  QrCodeConfigurationSidebar
);
